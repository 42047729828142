import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  getTicketDestinationBy,
  getTicketUrl as getTicketUrlApi,
} from "@/api/ticket";
import { useLoadingStore } from "@/stores/loading";
import * as Config from "@/config";
import MessageList from "@/components/Ticket/MessageList";
import MessageInputBox from "@/components/Ticket/MessageInputBox";

interface AttachedFile {
  original_filename: string;
  filepath: string;
}

export interface TicketComment {
  id: number;
  created: string;
  created_group_by: string;
  created_user_by: string;
  is_inquiry_user: boolean;
  comment: string;
  attached_files: AttachedFile[];
  ticket_destinations: TicketDestinations | null;
}

export interface Ticket {
  data_id: number;
  data_ticket_id: number;
  is_close: boolean;
  status: string;
  data_ticket_comments: TicketComment[];
  control_no: string;
  inquiry_company_name: string;
  inquiry_user_name: string;
}

export type TicketDestinations = {
  id: number;
  name: string;
  project_id: number;
  email_address: string;
};

export default function Ticket() {
  const setLoading = useLoadingStore((state) => state.setLoading);
  const pathname = Config.BASE_URL + Config.BASENAME + useLocation().pathname;
  const [ticket, setTicket] = useState<Ticket>();
  const [ticketDestList, setTicketDestList] = useState<TicketDestinations[]>(
    []
  );

  const getTicketUrl = useCallback(() => {
    Promise.all([getTicketUrlApi(pathname), getTicketDestinationBy(2)])
      .then((result) => {
        setTicket(result[0].data);
        setTicketDestList(result[1].data.ticketDestinations);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getTicketUrl();
  }, []);

  return (
    <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-large-bottom">
      <div className="uk-width-3-4">
        <div className="individual-ticket-sub-header">
          <div className="stick"></div>
          <div>問い合わせ番号　{ticket?.control_no}</div>
        </div>
        <div className="uk-margin-small-top">
          <table className="uk-width-medium">
            <tbody>
              <tr>
                <td className="uk-width-2-5 background-gray239">ステータス</td>
                <td>{ticket?.status}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <MessageList ticket={ticket} className="uk-margin-top" />
        <MessageInputBox
          ticket={ticket}
          getTicketUrl={getTicketUrl}
          ticketDestList={ticketDestList}
        />
      </div>
    </div>
  );
}