import ConfirmModal from "@/components/ConfirmModal";
import useAutosizeTextArea from "@/utils/useAutosizeTextArea";
import React, { Fragment, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import clipLIcon from "@/assets/images/clip_l.png";
import { Ticket, TicketDestinations } from "@/containers/Ticket/Ticket";
import { useLoadingStore } from "@/stores/loading";
import { useFieldArray, useForm } from "react-hook-form";
import {
  MESSAGE_NO_E08,
  MESSAGE_NO_E35,
  MESSAGE_NO_E41,
  MESSAGE_NO_E66,
} from "@/config";
import { postComment } from "@/api/ticket";
import { Simulate } from "react-dom/test-utils";
import submit = Simulate.submit;

interface Props {
  ticket?: Ticket;
  ticketDestList: TicketDestinations[];
  getTicketUrl: () => void;
}

export interface PostComment {
  data_id: number;
  data_ticket_id: number;
  is_inquiry_user: boolean;
  comment: string;
  ticket_destinations_id: number;
  attached_files?: {
    original_filename: string;
    file_content: string;
  }[];
}

interface ModalMessage {
  submit?: boolean;
  message?: string;
}

export default function MessageInputBox({
  ticket,
  ticketDestList,
  getTicketUrl,
}: Props) {
  const setLoading = useLoadingStore((state) => state.setLoading);

  const {
    register,
    trigger,
    watch,
    control,
    reset,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<PostComment>({
    reValidateMode: "onSubmit",
    values: {
      data_id: Number(ticket?.data_id),
      data_ticket_id: Number(ticket?.data_ticket_id),
      is_inquiry_user: true,
      comment: "",
      ticket_destinations_id: -1,
    },
  });
  const watchTextArea = watch("comment");
  const ticketDestinationId = watch("ticket_destinations_id");
  const { fields, append, remove } = useFieldArray({
    name: "attached_files",
    control,
  });

  useAutosizeTextArea("ticket-comment-textarea", watchTextArea);
  const [modalMessage, setModalMessage] = useState<ModalMessage>({});

  const onFileChange = (files: FileList) => {
    remove();
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target?.result;
        append({ original_filename: file.name, file_content: String(base64) });
      };
      reader.readAsDataURL(file);
    });
  };

  const onClickSend = (e: any) => {
    const setMessage =
      /*ticketDestinationId === -1
        ? { message: "送信先を選択してください。" }
        :*/ watchTextArea.length > 0
        ? {
            submit: true,
            message: MESSAGE_NO_E41,
          }
        : {
            message: MESSAGE_NO_E66,
          };
    setModalMessage(setMessage);
  };

  const onConfirm = () => {
    modalMessage.submit && onSubmit();
    setModalMessage({});
  };

  const onCancel = () => {
    setModalMessage({});
  };

  const onSubmit = () => {
    clearErrors();
    const data = getValues();
    trigger().then((isValid) => {
      if (!isValid) {
        console.log("バリデーションエラーがあります");
        return;
      }
      setLoading(true);
      postComment(data)
        .then((result) => {
          getTicketUrl();
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          reset();
          remove();
        });
    });
  };

  return (
    <>
      {/* <div className="message-sendto-box">
        <div>送信先</div>

        <select
          value={ticketDestinationId}
          onChange={(e) => {
            setValue("ticket_destinations_id", Number(e.target.value));
          }}
        >
          <option value={-1}>未選択</option>
          {ticketDestList.map((ticketDest) => (
            <option key={ticketDest.id} value={ticketDest.id}>
              {ticketDest.name}
            </option>
          ))}
        </select>
      </div> */}

      <div className="message-input-box">
        <textarea
          id="ticket-comment-textarea"
          {...register("comment", {
            required: MESSAGE_NO_E08("コメント"),
            maxLength: {
              value: 1000,
              message: MESSAGE_NO_E35("コメント", 1000),
            },
          })}
          rows={3}
        />
        {errors.comment && <p className="error">{errors.comment.message}</p>}
        <hr />
        <div className="uk-flex uk-flex-middle">
          <FileUploader
            classes="file-drop"
            name={"files"}
            handleChange={(files: any) => onFileChange(files)}
            multiple={true}
            children={
              <>
                {fields.length > 0 ? (
                  <div className="uk-flex">
                    {fields.map((file, index) => (
                      <Fragment key={index}>
                        <img
                          src={clipLIcon}
                          width={22}
                          height={22}
                          className="uk-margin-small-right"
                        />
                        {file.original_filename}
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <div className="uk-flex">
                    <img
                      src={clipLIcon}
                      width={22}
                      height={22}
                      className="uk-margin-small-right"
                    />
                    添付ファイルがある場合にはクリックして選択するか、ファイルをドロップしてください
                  </div>
                )}
              </>
            }
          />
          <button
            className="uk-button--m uk-button-refer"
            onClick={onClickSend}
          >
            送信
          </button>
        </div>
        <ConfirmModal
          text={modalMessage.message ?? ""}
          confirmButtonText="OK"
          isShow={!!modalMessage.message}
          onConfirm={onConfirm}
          onCancel={modalMessage.submit ? onCancel : undefined}
        />
      </div>
    </>
  );
}
